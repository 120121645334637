import React from 'react';
import { Routes, Route, HashRouter } from 'react-router-dom';

import Header from './Header';
import Footer from './Footer';

import Home from './Home';
import About from './About';
import Contact from './Contact';
import Ecommerce from './ECommerce';
import IntelliERP from './IntelliERP';
import QuickCommerce from './QuickCommerce';

function App() {
  return (
    <div>
      <HashRouter>
        <Header></Header>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about-us" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/ecommerce" element={<Ecommerce />} />
          <Route path="/quickcommerce" element={<QuickCommerce />} />
          <Route path="/intellierp" element={<IntelliERP />} />
        </Routes>
        <Footer></Footer>
      </HashRouter>
    </div>

  );
}

export default App;
