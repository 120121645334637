import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

function Header() {

    const navigate = useNavigate();

    const [showmobilemenu, setshowmobilemenu] = useState(false);

    const [showmobilesolutions, setshowmobilesolutions] = useState(false);

    const togglemobileNavigation = () => {

        if (showmobilemenu) {
            setshowmobilemenu(false)
        }
        else {
            setshowmobilemenu(true)
        }
    }

    const toggleshowmobilesolutions = () => {

        if (showmobilesolutions) {
            setshowmobilesolutions(false)
        }
        else {
            setshowmobilesolutions(true)
        }
    }

    const handleNavigation = (path) => {
        togglemobileNavigation(); // Call the function to toggle the mobile nav
        navigate(path); // Navigate to the new path
    };


    return (
        <div>
            <div className={`th-menu-wrapper ${showmobilemenu ? 'th-body-visible' : ''}`}>
                <div class="th-menu-area text-center">
                    <button class="th-menu-toggle" onClick={togglemobileNavigation}><i class="fal fa-times"></i></button>
                    <div class="mobile-logo">
                        <a onClick={() => handleNavigation('/')}><img src="../logo.png" style={{ width: 150 }} alt="Glancein" /></a>
                    </div>

                    <div class="th-mobile-menu">
                        <ul>
                            <li>
                                <a onClick={() => handleNavigation('/')}>Home</a>
                            </li>
                            <li>
                                <a onClick={() => handleNavigation('/about-us')}>About US</a>
                            </li>
                            <li class="menu-item-has-children">
                                <Link onClick={toggleshowmobilesolutions}>Our Solutions</Link>
                                <ul class="sub-menu th-submenu th-open" style={{ display: !showmobilesolutions ? 'none' : 'block' }}>
                                    <li><a onClick={() => handleNavigation('/ecommerce')}>E-Commerce</a></li>
                                    <li><a onClick={() => handleNavigation('/quickcommerce')}>Quick Commerce</a></li>
                                    <li><a onClick={() => handleNavigation('/intellierp')}>IntelliERP</a></li>
                                </ul>
                            </li>
                            <li>
                                <a onClick={() => handleNavigation('/Contact')}>Contact Us</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <header class="th-header default-header">
                <div class="sticky-wrapper">
                    <div class="menu-area">
                        <div class="container">
                            <div class="row align-items-center justify-content-between">
                                <div class="col-auto">
                                    <div class="header-logo" style={{ paddingTop: 0, paddingBottom: 0 }}>
                                        <Link class="icon-masking" to="/"><img src="../logo.png" style={{ width: 150 }} alt="Glancein" /></Link>
                                    </div>
                                </div>
                                <div class="col-auto">
                                    <nav class="main-menu style2 d-none d-lg-inline-block">
                                        <ul>
                                            <li>
                                                <Link to="/">Home</Link>
                                            </li>
                                            <li>
                                                <Link to="/about-us">About US</Link>
                                            </li>
                                            <li className="menu-item-has-children">
                                                <Link>Our Solutions</Link>
                                                <ul className="sub-menu">
                                                    <li><Link to="/ecommerce">E-Commerce</Link></li>
                                                    <li><Link to="/quickcommerce">Quick Commerce</Link></li>
                                                    <li><Link to="/intellierp">IntelliERP</Link></li>
                                                </ul>
                                            </li>
                                            <li>
                                                <Link to="/Contact">Contact Us</Link>
                                            </li>
                                        </ul>
                                    </nav>
                                    <div class="header-button">
                                        <button type="button" class="th-menu-toggle d-inline-block d-lg-none" onClick={togglemobileNavigation}><i class="far fa-bars"></i></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>

        </div>
    );
};

export default Header;
