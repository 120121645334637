import React, { useEffect } from 'react';

function QuickCommerce() {

    useEffect(() => {
        // Change the title when the Home component is mounted
        document.title = 'Quick Commerce - Glancein Enterprises Pvt Ltd';
    }, []);

    return (
        <div>
            <div class="breadcumb-wrapper " data-bg-src="../img/bg/breadcumb-bg.jpg">
                <div class="container">
                    <div class="breadcumb-content">
                        <h1 class="breadcumb-title">Quick Commerce</h1>
                        <ul class="breadcumb-menu">
                            <li><a href="home-hr-management.html">Home</a></li>
                            <li>eCommerce</li>
                        </ul>
                    </div>
                </div>
            </div>

            <section class="space" id="service-sec">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-lg-8">
                            <div class="title-area text-center">
                                <h2 class="sec-title">Stay Ahead of the Competition with Our Advanced SEO Services </h2>
                            </div>
                        </div>
                    </div>
                    <div class="row gy-4">
                        <div class="col-xxl-3 col-xl-4 col-md-6">
                            <div class="service-featured style2">
                                <div class="box-icon">
                                    <img src="../img/icon/service_1_1.svg" alt="Icon" />
                                </div>
                                <h3 class="box-title"><a href="service-details.html">Digital Marketing</a></h3>
                                <p class="box-text">Creating high-quality, relevant, & optimized content that attracts search traffic, engages users,</p>
                                <a href="service-details.html" class="th-btn">Read More<i class="fa-regular fa-arrow-right"></i></a>
                            </div>
                        </div>

                        <div class="col-xxl-3 col-xl-4 col-md-6">
                            <div class="service-featured style2">
                                <div class="box-icon">
                                    <img src="../img/icon/service_1_2.svg" alt="Icon" />
                                </div>
                                <h3 class="box-title"><a href="service-details.html">Keyword Research</a></h3>
                                <p class="box-text">Keyword research is a crucial step in SEO (Search Engine Optimization) and digital marketing</p>
                                <a href="service-details.html" class="th-btn">Read More<i class="fa-regular fa-arrow-right"></i></a>
                            </div>
                        </div>

                        <div class="col-xxl-3 col-xl-4 col-md-6">
                            <div class="service-featured style2">
                                <div class="box-icon">
                                    <img src="../img/icon/service_1_3.svg" alt="Icon" />
                                </div>
                                <h3 class="box-title"><a href="service-details.html">SEO Optimization</a></h3>
                                <p class="box-text">SEO (Search Engine Optimization) is the process of improving the visibility of a website or web page</p>
                                <a href="service-details.html" class="th-btn">Read More<i class="fa-regular fa-arrow-right"></i></a>
                            </div>
                        </div>

                        <div class="col-xxl-3 col-xl-4 col-md-6">
                            <div class="service-featured style2">
                                <div class="box-icon">
                                    <img src="../img/icon/service_1_4.svg" alt="Icon" />
                                </div>
                                <h3 class="box-title"><a href="service-details.html">Social Marketing</a></h3>
                                <p class="box-text">Social marketing involves promoting products, services ideas through social media platforms</p>
                                <a href="service-details.html" class="th-btn">Read More<i class="fa-regular fa-arrow-right"></i></a>
                            </div>
                        </div>

                        <div class="col-xxl-3 col-xl-4 col-md-6">
                            <div class="service-featured style2">
                                <div class="box-icon">
                                    <img src="../img/icon/service_1_5.svg" alt="Icon" />
                                </div>
                                <h3 class="box-title"><a href="service-details.html">Software Design</a></h3>
                                <p class="box-text">Software design involves defining the architecture, components, interfaces, and data for a software</p>
                                <a href="service-details.html" class="th-btn">Read More<i class="fa-regular fa-arrow-right"></i></a>
                            </div>
                        </div>

                        <div class="col-xxl-3 col-xl-4 col-md-6">
                            <div class="service-featured style2">
                                <div class="box-icon">
                                    <img src="../img/icon/service_1_6.svg" alt="Icon" />
                                </div>
                                <h3 class="box-title"><a href="service-details.html">Apps Development</a></h3>
                                <p class="box-text">App development is the process of designing, creating, and maintaining software applications</p>
                                <a href="service-details.html" class="th-btn">Read More<i class="fa-regular fa-arrow-right"></i></a>
                            </div>
                        </div>

                        <div class="col-xxl-3 col-xl-4 col-md-6">
                            <div class="service-featured style2">
                                <div class="box-icon">
                                    <img src="../img/icon/service_1_7.svg" alt="Icon" />
                                </div>
                                <h3 class="box-title"><a href="service-details.html">Market Analysis</a></h3>
                                <p class="box-text">Market analysis involves assessing various factors to understand a markets dynamics, trends</p>
                                <a href="service-details.html" class="th-btn">Read More<i class="fa-regular fa-arrow-right"></i></a>
                            </div>
                        </div>

                        <div class="col-xxl-3 col-xl-4 col-md-6">
                            <div class="service-featured style2">
                                <div class="box-icon">
                                    <img src="../img/icon/service_1_8.svg" alt="Icon" />
                                </div>
                                <h3 class="box-title"><a href="service-details.html">Email Marketing</a></h3>
                                <p class="box-text">Email marketing is a powerful tool for building relationships with customers, promoting products</p>
                                <a href="service-details.html" class="th-btn">Read More<i class="fa-regular fa-arrow-right"></i></a>
                            </div>
                        </div>

                    </div>
                </div>
            </section>

            <div class=" overflow-hidden">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-lg-6">
                            <div class="title-area text-center" style={{ marginTop: 20 }}>
                                <h2 class="sec-title">The Most Intuitive App For You Needs</h2>
                            </div>
                        </div>
                    </div>
                    <div class="row gy-4 justify-content-center justify-content-lg-between">
                        <div class="col-xl-3 col-md-6">
                            <div class="choose-feature th-ani" data-bg-color="#EFEAFF">
                                <div class="box-img">
                                    <img src="../img/shape/feature_box_1.svg" alt="Icon" />
                                </div>
                                <div class="box-content">
                                    <h3 class="box-title">Strong and Secure</h3>
                                    <p class="box-text">Built with top-tier security protocols, including a dedicated virtual network and firewalls.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-3 col-md-6">
                            <div class="choose-feature th-ani" data-bg-color="#FFF7DD">
                                <div class="box-img">
                                    <img src="../img/shape/feature_box_2.svg" alt="Icon" />
                                </div>
                                <div class="box-content">
                                    <h3 class="box-title">Global Availability</h3>
                                    <p class="box-text">Multiple geo replicas for seamless backups and fast disaster recovery, ensuring zero downtime.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-3 col-md-6">
                            <div class="choose-feature th-ani" data-bg-color="#E6FFD0">
                                <div class="box-img">
                                    <img src="../img/shape/feature_box_3.svg" alt="Icon" />
                                </div>
                                <div class="box-content">
                                    <h3 class="box-title">Storage & Privacy</h3>
                                    <p class="box-text">Your data is securely encrypted and stored with strong tokens, ensuring privacy.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-3 col-md-6">
                            <div class="choose-feature th-ani" data-bg-color="#FFE9ED">
                                <div class="box-img">
                                    <img src="../img/shape/feature_box_4.svg" alt="Icon" />
                                </div>
                                <div class="box-content">
                                    <h3 class="box-title">Cloud Technology</h3>
                                    <p class="box-text">Powered by Microsoft Azure, our platform offers full automation and scalability.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="overflow-hidden space" id="faq-sec">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-lg-8">
                            <div class="title-area">
                                <span class="sub-title">FAQ'S</span>
                                <h2 class="sec-title">Frequently Ask Questions</h2>
                            </div>
                            <div class="accordion-area accordion" id="faqAccordion">


                                <div class="accordion-card style3 style1 ">
                                    <div class="accordion-header" id="collapse-item-1">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-1" aria-expanded="false" aria-controls="collapse-1">Will this app support any business model?</button>
                                    </div>
                                    <div id="collapse-1" class="accordion-collapse collapse " aria-labelledby="collapse-item-1" data-bs-parent="#faqAccordion">
                                        <div class="accordion-body">
                                            <p class="faq-text">Yes, if you're running a business that deals with physical goods, this app is an ideal choice to set up. However, it's important to ensure that your business complies with the necessary regulations, legal approvals, and registration documents required to operate.</p>
                                        </div>
                                    </div>
                                </div>


                                <div class="accordion-card style3 style1 active">
                                    <div class="accordion-header" id="collapse-item-2">
                                        <button class="accordion-button " type="button" data-bs-toggle="collapse" data-bs-target="#collapse-2" aria-expanded="true" aria-controls="collapse-2">Will there be any additional charges after purchasing the software subscription?</button>
                                    </div>
                                    <div id="collapse-2" class="accordion-collapse collapse show" aria-labelledby="collapse-item-2" data-bs-parent="#faqAccordion">
                                        <div class="accordion-body">
                                            <p class="faq-text">You will need to cover regular cloud hosting charges based on your required configuration, as well as SMS alerts and standard payment gateway charges, which may vary depending on your agreement with the payment gateway provider. Additionally, there will be charges for using the Google Maps API. All these costs depend on your configuration and usage volume.</p>
                                        </div>
                                    </div>
                                </div>


                                <div class="accordion-card style3 style1 ">
                                    <div class="accordion-header" id="collapse-item-3">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-3" aria-expanded="false" aria-controls="collapse-3">How will the shipment or delivery be handled?</button>
                                    </div>
                                    <div id="collapse-3" class="accordion-collapse collapse " aria-labelledby="collapse-item-3" data-bs-parent="#faqAccordion">
                                        <div class="accordion-body">
                                            <p class="faq-text">We are partnered with multiple shipment handlers, and you have the option to choose from them or use your own delivery process. You can track the entire delivery process and shipment details through our delivery partner app.</p>
                                        </div>
                                    </div>
                                </div>


                                <div class="accordion-card style3 style1 ">
                                    <div class="accordion-header" id="collapse-item-4">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-4" aria-expanded="false" aria-controls="collapse-4">What is a Quick Commerce ?</button>
                                    </div>
                                    <div id="collapse-4" class="accordion-collapse collapse " aria-labelledby="collapse-item-4" data-bs-parent="#faqAccordion">
                                        <div class="accordion-body">
                                            <p class="faq-text">Quick commerce (q-commerce) is a type of e-commerce that focuses on delivering products and services to customers rapidly, typically within hours or even minutes. It represents the next step in the evolution of e-commerce, characterized by speed and convenience. Examples include businesses like restaurants, grocery stores, milk delivery, and more.</p>
                                        </div>
                                    </div>
                                </div>


                                <div class="accordion-card style3 style1 ">
                                    <div class="accordion-header" id="collapse-item-5">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-5" aria-expanded="false" aria-controls="collapse-5">Can I automate the settlement of vendor, Suppliers and delivery partner payments?</button>
                                    </div>
                                    <div id="collapse-5" class="accordion-collapse collapse " aria-labelledby="collapse-item-5" data-bs-parent="#faqAccordion">
                                        <div class="accordion-body">
                                            <p class="faq-text">Yes, you can automate this process. We have partnered with multiple payment gateways to handle payment settlements. Once an order is delivered, you can automatically split the payments between your company, suppliers, vendors, and delivery partners.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <aside class="sidebar-area style2">
                                <div class="widget widget_search   ">
                                    <form class="search-form">
                                        <input type="text" placeholder="Enter Keyword" />
                                        <button type="submit"><i class="far fa-search"></i></button>
                                    </form>
                                </div>
                                <div class="widget widget_offer  ">
                                    <div class="offer-banner">
                                        {/* <div class="banner-logo">
                                        <img src="../white.png" style={{ width: 150 }} alt="Sassa" />
                                    </div> */}
                                        <h5 class="banner-title">Need Help? We Are Here To Help You</h5>
                                        <div class="offer">
                                            <h6 class="offer-title">Glancein Special Discount</h6>
                                            <p class="offer-text">Save up to <span class="text-theme">60% off</span></p>
                                            <a href="contact.html" class="th-btn">Get A Quote</a>
                                        </div>
                                    </div>
                                </div>
                            </aside>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default QuickCommerce