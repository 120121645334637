import React, { useEffect, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import axios from 'axios';

function Contact() {
    const [captchaVerified, setCaptchaVerified] = useState(false);


    useEffect(() => {
        // Change the title when the Home component is mounted
        document.title = 'Contact - Glancein Enterprises Pvt Ltd';
    }, []);


    const handleCaptcha = (value) => {
        if (value) {
            const apiUrl = `https://recaptchaenterprise.googleapis.com/v1/projects/glanceindev/assessments?key=AIzaSyCVsi16jqBtlNBGli7hRcX4FAj-qf2fn2I`;

            const requestBody = {
                event: {
                    token: value,
                    expectedAction: 'USER_ACTION',
                    siteKey: '6Lc_xEUqAAAAACETWIlvEXL7pOQ5qK4PPp5OmFYo'
                }
            };

            axios.post(apiUrl, requestBody, {
                headers: {
                    'Content-Type': 'application/json',
                },
            })
                .then((response) => {
                    const { tokenProperties } = response.data;
                    // Update the state based on token validity
                    if (tokenProperties.valid) {
                        setCaptchaVerified(true);
                        console.log('Captcha verified successfully:', response.data);
                    } else {
                        setCaptchaVerified(false);
                        console.log('Invalid captcha:', response.data);
                    }
                })
                .catch((error) => {
                    console.error('Error verifying captcha:', error);
                });
        } else {
            setCaptchaVerified(false);
        }
    };



    return (
        <div>
            <div class="breadcumb-wrapper">
                <div class="container">
                    <div class="breadcumb-content">
                        <h1 class="breadcumb-title">Contact Us</h1>
                        <ul class="breadcumb-menu">
                            <li><a href="/">Home</a></li>
                            <li>Contact Us</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="space">
                <div class="container">
                    <div class="row gy-30 gx-30">
                        <div class="col-md-6 col-xl-3 contact-info_wrapp">
                            <div class="contact-info">
                                <div class="contact-info_icon"><img src="../img/icon/address.svg" alt="" /></div>
                                <div class="media-body">
                                    <p class="contact-info_label">Address</p>
                                    <a href="https://www.google.com/maps" class="contact-info_link">Visakhapatnam
                                        <span>Andhra Pradesh</span></a>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 col-xl-3 contact-info_wrapp">
                            <div class="contact-info">
                                <div class="contact-info_icon"><img src="../img/icon/call-2.svg" alt="" /></div>
                                <div class="media-body">
                                    <p class="contact-info_label">Phone</p>
                                    <a href="tel:+58-125253158" class="contact-info_link">+91 99999 99999</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 col-xl-3 contact-info_wrapp">
                            <div class="contact-info">
                                <div class="contact-info_icon"><img src="../img/icon/mail-2.svg" alt="" /></div>
                                <div class="media-body">
                                    <p class="contact-info_label">Email</p>
                                    <a href="mailto:info@sassa.com" class="contact-info_link">contact@glancein.com</a>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 col-xl-3 contact-info_wrapp">
                            <div class="contact-info">
                                <div class="contact-info_icon"><img src="../img/icon/qus.svg" alt="" /></div>
                                <div class="media-body">
                                    <p class="contact-info_label">Have Questions?</p>
                                    <span class="contact-info_text">Discover more by visiting us or joining our community</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="space-bottom">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-6">
                            <div class="">
                                <form class="contact-form style2 ajax-contact" style={{ height: 650 }}>
                                    <div class="row">
                                        <div class="col-md-6 form-group">
                                            <input type="text" placeholder="Your name*" class="form-control" />
                                            <i class="far fa-user"></i>
                                        </div>
                                        <div class="col-md-6 form-group">
                                            <input type="email" placeholder="Email address*" class="form-control" />
                                            <i class="far fa-envelope"></i>
                                        </div>
                                        <div class="col-md-6 form-group">
                                            <input type="tel" class="form-control" name="number" id="number" placeholder="Phone number*" />
                                            <i class="fal fa-phone"></i>
                                        </div>
                                        <div class="form-group col-md-6">
                                            <select name="subject" id="subject" class="form-select nice-select">
                                                <option value="" disabled selected hidden>Select Topic*</option>
                                                <option value="Web Development">E-Commerce</option>
                                                <option value="Cyber Security">Quick Commerce</option>
                                                <option value="App Development">Intelli ERP</option>
                                                <option value="App Development">Other</option>
                                            </select>
                                        </div>
                                        <div class="form-group col-12">
                                            <textarea name="message" id="message" cols="30" rows="3" class="form-control" placeholder="Write a message*"></textarea>
                                            <i class="fal fa-pencil"></i>
                                        </div>
                                        <ReCAPTCHA
                                            sitekey="6Lc_xEUqAAAAACETWIlvEXL7pOQ5qK4PPp5OmFYo"
                                            onChange={handleCaptcha}
                                            style={{ marginBottom: 20 }}
                                        />
                                        <div class="col-12 form-group mb-0">
                                            <button title={!captchaVerified ? 'Please Verify you are human' : ''} style={!captchaVerified ? { backgroundColor: 'grey', cursor: 'not-allowed' } : null} disabled={!captchaVerified} class="th-btn btn-fw">Send Message</button>
                                        </div>
                                    </div>
                                    <p class="form-messages mb-0 mt-3"></p>
                                </form>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="contact-image style2">
                                <img src="../img/normal/contact-image.png" style={{ borderRadius: 30 }} alt="contact" />
                            </div>
                        </div>
                    </div>


                    {/* <div class="">
                        <div class="contact-map style2">
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3644.7310056272386!2d89.2286059153658!3d24.00527418490799!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39fe9b97badc6151%3A0x30b048c9fb2129bc!2sAngfuztheme!5e0!3m2!1sen!2sbd!4v1651028958211!5m2!1sen!2sbd" allowfullscreen="" loading="lazy"></iframe>
                            <div class="contact-icon">
                                <i class="fa-solid fa-location-dot"></i>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
        </div>
    )
}

export default Contact;