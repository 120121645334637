import React, { useEffect } from 'react';

function Home() {

    useEffect(() => {
        // Change the title when the Home component is mounted
        document.title = 'Home - Glancein Enterprises Pvt Ltd';
      }, []);

    return (
        <div style={{ marginTop: -150 }}>
            <div class="th-hero-wrapper hero-1" id="hero">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-xl-6">
                            <div class="hero-style1">
                                <span class="sub-title">Welcome to Glancein</span>
                                <h1 class="hero-title">Transforming Challenges into Opportunities</h1>
                                <p class="hero-text">Empower your business with easy-to-use, scalable eCommerce and quick commerce SaaS solutions designed for growth.</p>
                                <div class="btn-group">
                                    <a href="about.html" class="th-btn">GET A QUOTE</a>
                                    {/* <div class="call-btn">
                                        <a  class="play-btn popup-video">
                                            <i class="fas fa-play"></i>
                                        </a>
                                        <div class="media-body">
                                            <a 
                                                class="btn-title popup-video">Watch
                                                Video</a>
                                        </div>
                                    </div> */}
                                </div>
                                {/* <div class="client-box mb-sm-0 mb-3">
                                    <div class="client-thumb-group">
                                        <div class="thumb"><img src="https://html.themeholy.com/sassa/demo/assets/img/shape/client-1-1.png" alt="avater" /></div>
                                        <div class="thumb"><img src="https://html.themeholy.com/sassa/demo/assets/img/shape/client-1-2.png" alt="avater" /></div>
                                        <div class="thumb"><img src="https://html.themeholy.com/sassa/demo/assets/img/shape/client-1-3.png" alt="avater" /></div>
                                        <div class="thumb icon"><i class="fa-regular fa-plus"></i></div>
                                    </div>
                                    <div class="cilent-box">
                                        <h4 class="box-title"><span class="counter-number">10</span>k+ Happy Clients</h4>
                                        <div class="client-wrapp">
                                            <div class="client-review">
                                                <i class="fa-sharp fa-solid fa-star"></i><i
                                                    class="fa-sharp fa-solid fa-star"></i><i
                                                        class="fa-sharp fa-solid fa-star"></i><i
                                                            class="fa-sharp fa-solid fa-star"></i>
                                                <i class="fa-solid fa-star-half-stroke"></i>
                                            </div>
                                            <span class="rating">4.8 Rating</span>
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                        </div>
                        <div class="col-xl-6">
                            <div class="hero-img tilt-active">
                                <img src="../img/hero/hero_1_1.png" alt="Hero" />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="ripple-shape">
                    <span class="ripple-1"></span>
                    <span class="ripple-2"></span>
                    <span class="ripple-3"></span>
                    <span class="ripple-4"></span>
                    <span class="ripple-5"></span>
                </div>
                <div class="th-circle">
                    <span class="circle style1"></span>
                    <span class="circle style2"></span>
                    <span class="circle style3"></span>
                </div>
            </div>

            <div class="position-relative overflow-hidden space-bottom" style={{ marginTop: 30 }}>
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-lg-7">
                            <div class="title-area text-center">
                                <span class="sub-title">Our uniqueness</span>
                                <h2 class="sec-title">Flexibility at Every Step</h2>
                                <p class="sec-text">Our eCommerce and quick commerce SaaS solutions are designed to adapt to your business needs, offering scalable and customizable options for businesses of all sizes.</p>
                            </div>
                        </div>
                    </div>
                    <div class="row gy-4 justify-content-center">
                        <div class="col-md-6 col-xl-3">
                            <div class="feature-card th-ani">
                                <div class="box-icon">
                                    <img src="../img/icon/feature_1_1.svg" alt="Icon" />
                                </div>
                                <h3 class="box-title"><a href="service-details.html">Artificial Intelligence</a></h3>
                                <p class="box-text">Integrates with AI and ERP solutions to deliver enhanced functionality, empowering businesses with smarter automation, efficient resource management, and data-driven decision-making for optimized performance.</p>
                            </div>
                        </div>

                        <div class="col-md-6 col-xl-3">
                            <div class="feature-card th-ani">
                                <div class="box-icon">
                                    <img src="../img/icon/feature_1_2.svg" alt="Icon" />
                                </div>
                                <h3 class="box-title"><a href="service-details.html">Customization Option</a></h3>
                                <p class="box-text">You can fully customize our application to align with your specific business model. Tailor features, workflows, and integrations to meet your unique needs, ensuring the platform scales and adapts seamlessly as your business grows.</p>
                            </div>
                        </div>

                        <div class="col-md-6 col-xl-3">
                            <div class="feature-card th-ani">
                                <div class="box-icon">
                                    <img src="../img/icon/feature_1_3.svg" alt="Icon" />
                                </div>
                                <h3 class="box-title"><a href="service-details.html">Technical Support</a></h3>
                                <p class="box-text">Our dedicated technical team is here to assist you at every step of setting up your store. If you encounter any challenges, we offer live support and language assistance, ensuring a smooth experience with personalized guidance.</p>
                            </div>
                        </div>

                        <div class="col-md-6 col-xl-3">
                            <div class="feature-card th-ani">
                                <div class="box-icon">
                                    <img src="../img/icon/feature_1_4.svg" alt="Icon" />
                                </div>
                                <h3 class="box-title"><a href="service-details.html">Subscription Plans</a></h3>
                                <p class="box-text">Our friendly subscription plans are designed to support startups and businesses of all sizes, with flexible options that empower you to grow. Our SaaS platform provides continuous support and scalability to ensure your success.</p>
                            </div>
                        </div>

                    </div>
                </div>
                <div class="shape-mockup z-index-3 movingX d-none d-xl-block" data-top="18%" data-left="18%"><span
                    class="feature-shape style1"></span></div>
                <div class="shape-mockup movingX d-none d-xl-block" data-top="15%" data-right="15%"><span
                    class="feature-shape style2"></span></div>
                <div class="shape-mockup movingX d-none d-xl-block" data-bottom="30%" data-left="5%"><span
                    class="feature-shape style3"></span></div>
                <div class="shape-mockup movingX d-none d-xl-block" data-bottom="20%" data-right="10%"><span
                    class="feature-shape style4"></span></div>
            </div>

            <section class="position-relative overflow-hidden space-bottom" id="process-sec">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-xl-6">
                            <div class="title-area mb-xl-10 text-center">
                                <span class="sub-title">How It Work</span>
                                <h2 class="sec-title">Make Your Device Manage Everything For You!</h2>
                            </div>
                        </div>
                    </div>
                    <div class="step-wrap">
                        <div class="process-line">
                            <img src="../img/shape/process-line.png" alt="" />
                        </div>
                        <div class="process-card_wrapp">
                            <div class="process-card">
                                <div class="pulse"></div>
                                <div class="box-icon">
                                    <span class="number">Step - 01</span>
                                </div>
                                <div class="box-content">
                                    <h2 class="box-title">Create Account</h2>
                                    <p class="box-text">Create your account, select the perfect plan, and get started.</p>

                                </div>
                                <div class="box-img">
                                    <img class="" src="../img/shape/process_1_1.png" alt="Process" />
                                </div>
                            </div>
                            <div class="process-card">
                                <div class="pulse"></div>
                                <div class="box-icon">
                                    <span class="number">Step - 02</span>
                                </div>
                                <div class="box-content">
                                    <h2 class="box-title">Setup</h2>
                                    <p class="box-text">Set up your inventory, locations, and payment options with ease.</p>

                                </div>
                                <div class="box-img">
                                    <img class="" src="../img/shape/process_1_2.png" alt="Process" />
                                </div>
                            </div>
                            <div class="process-card">
                                <div class="pulse"></div>
                                <div class="box-icon">
                                    <span class="number">Step - 03</span>
                                </div>
                                <div class="box-content">
                                    <h2 class="box-title">Go Live, & Enjoy</h2>
                                    <p class="box-text">Go live, start accepting orders, and grow your business quickly.</p>

                                </div>
                                <div class="box-img">
                                    <img class="" src="../img/shape/process_1_3.png" alt="Process" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <div class="about-area2 ms-80 me-80 th-radius5 overflow-hidden space" id="about-sec">
                <div class="container">
                    <div class="row gy-4 align-items-center">
                        <div class="col-xl-6 mb-30 mb-xl-0">
                            <div class="img-box4 me-xl-5">
                                <div class="img1">
                                    <img src="../img/normal/about_2_1.png" style={{ borderRadius: 50}} alt="About" />
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-6">
                            <div class="title-area mb-35">
                                <span class="sub-title">About Our Saas application</span>
                                <h2 class="sec-title">Analytics & Accounting</h2>
                            </div>
                            <p class="mt-n2 mb-25">Our platform offers AI-powered analytical reports and an intuitive dashboard, providing deep insights into your business operations. With integrated accounting features such as Balance Sheet, Trial Balance, vouchers, expenses, and purchases, you can easily track inputs and outputs. Manage your financials, monitor performance, and make data-driven decisions—all in one place. Additionally, our solution supports expense tracking, purchase management, and much more to streamline your accounting processes and keep your business on the path to growth.</p>
                            <div class="checklist style3 mb-40">
                                <ul>
                                    <li><i class="fa-solid fa-circle-check"></i>Personalized Analytics Dashboard</li>
                                    <li><i class="fa-solid fa-circle-check"></i>Integrated with Standard Indian Accounting System</li>
                                    <li><i class="fa-solid fa-circle-check"></i>Vendor & delivery Partner Applications</li>
                                </ul>
                            </div>
                            <a href="about.html" class="th-btn">Discover More</a>
                        </div>
                    </div>
                    <div class="row gy-4 space-top flex-row-reverse align-items-center">
                        <div class="col-xl-6 mb-30 mb-xl-0">
                            <div class="img-box5 ms-xl-5">
                                <div class="img1">
                                    <img src="../img/normal/about_3_1.png" alt="About" />
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-6">
                            <div class="title-area mb-35">
                                <span class="sub-title">App Advantage</span>
                                <h2 class="sec-title">Comprehensive Options to Meet All Your Business Needs</h2>
                            </div>
                            <p class="mt-n2 mb-25">Our fully integrated application meets all your business needs, featuring AI-based search, load balancers for efficient scaling, and dedicated apps for vendors and delivery partners. Streamline operations and drive growth with our comprehensive solution.</p>
                            <div class="two-column mb-40 m-sm-0 list-center ms-0">
                                <div class="checklist style6">
                                    <ul>
                                        <li><i class="fas fa-check-circle"></i> Supports Android & IOS</li>
                                        <li><i class="fas fa-check-circle"></i> Admin Apps & Vendor Apps</li>
                                        <li><i class="fas fa-check-circle"></i> inventory Management</li>
                                        <li><i class="fas fa-check-circle"></i> Live Order Tracking</li>
                                    </ul>
                                </div>
                                <div class="checklist style6">
                                    <ul>
                                        <li><i class="fas fa-check-circle"></i> AI Based Catelog Search</li>
                                        <li><i class="fas fa-check-circle"></i> Unlimited Feature Updates</li>
                                        <li><i class="fas fa-check-circle"></i> Coupons & Promo Codes</li>
                                        <li><i class="fas fa-check-circle"></i> Realtime Push Notifications</li>
                                    </ul>
                                </div>
                            </div>
                            <a href="about.html" class="th-btn mt-10">Get Started</a>
                        </div>
                    </div>
                </div>
                <div class="shape-mockup z-index-3 spin d-none d-xl-block" data-top="5%" data-left="5%"><img src="https://html.themeholy.com/sassa/demo/assets/img/shape/star-5.png" alt="" /></div>
                <div class="shape-mockup spin d-none d-xl-block" data-top="10%" data-right="7%"><img src="https://html.themeholy.com/sassa/demo/assets/img/shape/star-6.png" alt="" /></div>
                <div class="shape-mockup spin d-none d-xl-block" data-top="49%" data-left="10%"><img src="https://html.themeholy.com/sassa/demo/assets/img/shape/star-7.png" alt="" /></div>
                <div class="shape-mockup spin d-none d-xl-block" data-top="40%" data-right="12%"><img src="https://html.themeholy.com/sassa/demo/assets/img/shape/star-8.png" alt="" /></div>
                <div class="shape-mockup spin d-none d-xl-block" data-bottom="10%" data-left="43%"><img src="https://html.themeholy.com/sassa/demo/assets/img/shape/star-9.png" alt="" /></div>
                <div class="shape-mockup spin d-none d-xl-block" data-bottom="5%" data-right="4%"><img src="https://html.themeholy.com/sassa/demo/assets/img/shape/star-10.png" alt="" /></div>
                <div class="shape-mockup d-none d-xxl-block" data-top="33%" data-right="18%"><img src="https://html.themeholy.com/sassa/demo/assets/img/shape/arrow-line.png" alt="" /></div>

            </div>

            <div class=" overflow-hidden space">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-lg-6">
                            <div class="title-area text-center">
                                <span class="sub-title">Our Features</span>
                                <h2 class="sec-title">The Most Intuitive App For You Needs</h2>
                            </div>
                        </div>
                    </div>
                    <div class="row gy-4 justify-content-center justify-content-lg-between">
                        <div class="col-xl-3 col-md-6">
                            <div class="choose-feature th-ani" data-bg-color="#EFEAFF">
                                <div class="box-img">
                                    <img src="../img/shape/feature_box_1.svg" alt="Icon" />
                                </div>
                                <div class="box-content">
                                    <h3 class="box-title">Strong and Secure</h3>
                                    <p class="box-text">Built with top-tier security protocols, including a dedicated virtual network and firewalls.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-3 col-md-6">
                            <div class="choose-feature th-ani" data-bg-color="#FFF7DD">
                                <div class="box-img">
                                    <img src="../img/shape/feature_box_2.svg" alt="Icon" />
                                </div>
                                <div class="box-content">
                                    <h3 class="box-title">Global Availability</h3>
                                    <p class="box-text">Multiple geo replicas for seamless backups and fast disaster recovery, ensuring zero downtime.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-3 col-md-6">
                            <div class="choose-feature th-ani" data-bg-color="#E6FFD0">
                                <div class="box-img">
                                    <img src="../img/shape/feature_box_3.svg" alt="Icon" />
                                </div>
                                <div class="box-content">
                                    <h3 class="box-title">Storage & Privacy</h3>
                                    <p class="box-text">Your data is securely encrypted and stored with strong tokens, ensuring privacy.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-xl-3 col-md-6">
                            <div class="choose-feature th-ani" data-bg-color="#FFE9ED">
                                <div class="box-img">
                                    <img src="../img/shape/feature_box_4.svg" alt="Icon" />
                                </div>
                                <div class="box-content">
                                    <h3 class="box-title">Cloud Technology</h3>
                                    <p class="box-text">Powered by Microsoft Azure, our platform offers full automation and scalability.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <section class="space">
                <div class="container">
                    <div class="title-area text-center">
                        <span class="sub-title">
                            Our Pricing
                        </span>
                        <h2 class="sec-title">Choose The Plans That Suits You!</h2>
                        <p>Choose a plan that suits your business needs</p>
                        <div class="pricing-tabs">
                            <div class="switch-area">
                                <label class="toggler toggler--is-active ms-0" id="filt-monthly">Monthly</label>
                                <div class="toggle">
                                    <input type="checkbox" id="switcher" class="check" />
                                    <b class="b switch"></b>
                                </div>
                                <label class="toggler" id="filt-yearly">Yearly</label>
                            </div>
                            <div class="discount-tag">
                                <svg width="54" height="41" viewBox="0 0 54 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M15.5389 7.99353C15.4629 8.44111 15.3952 8.82627 15.3583 9.02966C15.1309 10.2666 14.942 13.4078 14.062 15.5433C13.3911 17.1727 12.3173 18.2233 10.6818 17.8427C9.19525 17.4967 8.26854 16.0251 7.82099 13.9916C6.85783 9.61512 8.00529 2.6265 8.90147 0.605294C8.99943 0.384693 9.25826 0.284942 9.48075 0.382666C9.70224 0.479891 9.80333 0.737018 9.70537 0.957619C8.84585 2.89745 7.75459 9.6061 8.67913 13.8076C9.04074 15.4498 9.68015 16.7144 10.881 16.9937C12.0661 17.2698 12.7622 16.3933 13.2485 15.2121C14.1054 13.134 14.273 10.0757 14.4938 8.87118C14.6325 8.11613 15.0798 5.22149 15.1784 4.9827C15.3016 4.68358 15.5573 4.69204 15.641 4.70108C15.7059 4.708 16.0273 4.76322 16.0423 5.15938C16.2599 10.808 20.5327 19.3354 26.8096 25.0475C33.0314 30.7095 41.2522 33.603 49.4783 28.0026C49.6784 27.8669 49.9521 27.9178 50.0898 28.1157C50.2269 28.3146 50.1762 28.5863 49.9762 28.7219C41.3569 34.5897 32.7351 31.6217 26.217 25.6902C20.7234 20.6913 16.7462 13.5852 15.5389 7.99353Z"
                                        fill="var(--theme-color)" />
                                    <path fill-rule="evenodd" clip-rule="evenodd"
                                        d="M49.2606 28.5952C48.2281 28.5096 47.1974 28.4571 46.1708 28.2919C43.4358 27.8522 40.6863 26.8206 38.4665 25.1551C38.2726 25.0089 38.2345 24.7355 38.3799 24.5438C38.5267 24.3517 38.8021 24.3145 38.9955 24.4592C41.1013 26.0411 43.7143 27.0136 46.3092 27.4305C47.4844 27.6191 48.6664 27.6581 49.8489 27.7714C49.9078 27.7778 50.4232 27.8114 50.53 27.8482C50.7793 27.9324 50.8288 28.1252 50.8402 28.2172C50.8506 28.2941 50.8446 28.3885 50.7944 28.4939C50.7528 28.5801 50.6349 28.7253 50.4357 28.886C49.7992 29.4029 48.1397 30.3966 47.8848 30.5884C44.9622 32.7862 42.6161 35.3187 40.0788 37.9235C39.9097 38.0958 39.6311 38.1004 39.4566 37.9332C39.2821 37.766 39.2778 37.49 39.4459 37.3172C42.0151 34.6792 44.3946 32.1179 47.353 29.8939C47.5278 29.7615 48.5366 29.0813 49.2606 28.5952Z"
                                        fill="var(--theme-color)" />
                                </svg>
                                Save 35%
                            </div>
                        </div>
                    </div>
                    <div id="monthly" class="wrapper-full">
                        <div class="row justify-content-center">

                            <div class="col-xl-4 col-md-6">
                                <div class="price-box th-ani ">
                                    <div class="price-title-wrap">
                                        <h3 class="box-title">Basic Plan</h3>
                                        <p class="subtitle">FREE</p>
                                    </div>
                                    <p class="box-text">Perfect plan to get started</p>
                                    <h4 class="box-price">$00.00<span class="duration">/month</span></h4>
                                    <p class="box-text2">A free plan grants you access to some cool features of Spend.</p>
                                    <div class="box-content">
                                        <div class="available-list">
                                            <ul>
                                                <li>Limited Access Library</li>
                                                <li>Commercia License</li>
                                                <li>Hotline Support 24/7</li>
                                                <li class="unavailable">100+ HTML UI Elements</li>
                                                <li class="unavailable">WooCommerce Builder</li>
                                                <li class="unavailable">Updates for 1 Year</li>
                                            </ul>
                                        </div>
                                        <a href="contact.html" class="th-btn btn-fw style-radius">Get Your Free Plan</a>
                                    </div>
                                </div>
                            </div>

                            <div class="col-xl-4 col-md-6">
                                <div class="price-box th-ani active">
                                    <div class="price-title-wrap">
                                        <h3 class="box-title">Standard Plan</h3>
                                        <p class="subtitle">ULTIMATE</p>
                                    </div>
                                    <p class="box-text">Best suits for great company!</p>
                                    <h4 class="box-price">$49.00<span class="duration">/month</span></h4>
                                    <p class="box-text2">If you a finance manager at big company, this plan is a perfect match.
                                    </p>
                                    <div class="box-content">
                                        <div class="available-list">
                                            <ul>
                                                <li>Limited Access Library</li>
                                                <li>Commercia License</li>
                                                <li>Hotline Support 24/7</li>
                                                <li class="unavailable">100+ HTML UI Elements</li>
                                                <li class="unavailable">WooCommerce Builder</li>
                                                <li class="unavailable">Updates for 1 Year</li>
                                            </ul>
                                        </div>
                                        <a href="contact.html" class="th-btn btn-fw style-radius">Get Your Standard plan</a>
                                    </div>
                                </div>
                            </div>

                            <div class="col-xl-4 col-md-6">
                                <div class="price-box th-ani ">
                                    <div class="price-title-wrap">
                                        <h3 class="box-title">Premium Plan</h3>
                                        <p class="subtitle">PRO</p>
                                    </div>
                                    <p class="box-text">Perfect plan for professionals!</p>
                                    <h4 class="box-price">$99.00<span class="duration">/month</span></h4>
                                    <p class="box-text2">For professional only! Start arranging your expenses with our best
                                        templates.</p>
                                    <div class="box-content">
                                        <div class="available-list">
                                            <ul>
                                                <li>Limited Access Library</li>
                                                <li>Commercia License</li>
                                                <li>Hotline Support 24/7</li>
                                                <li class="unavailable">100+ HTML UI Elements</li>
                                                <li class="unavailable">WooCommerce Builder</li>
                                                <li class="unavailable">Updates for 1 Year</li>
                                            </ul>
                                        </div>
                                        <a href="contact.html" class="th-btn btn-fw style-radius">Get Your Premium Plan</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="yearly" class="wrapper-full hide">
                        <div class="row justify-content-center">

                            <div class="col-xl-4 col-md-6">
                                <div class="price-box th-ani ">
                                    <div class="price-title-wrap">
                                        <h3 class="box-title">Basic Plan</h3>
                                        <p class="subtitle">FREE</p>
                                    </div>
                                    <p class="box-text">Perfect plan to get started</p>
                                    <h4 class="box-price">$199.00<span class="duration">/month</span></h4>
                                    <p class="box-text2">A free plan grants you access to some cool features of Spend.</p>
                                    <div class="box-content">
                                        <div class="available-list">
                                            <ul>
                                                <li>Limited Access Library</li>
                                                <li>Commercia License</li>
                                                <li>Hotline Support 24/7</li>
                                                <li class="unavailable">100+ HTML UI Elements</li>
                                                <li class="unavailable">WooCommerce Builder</li>
                                                <li class="unavailable">Updates for 1 Year</li>
                                            </ul>
                                        </div>
                                        <a href="contact.html" class="th-btn btn-fw style-radius">Get Your Free Plan</a>
                                    </div>
                                </div>
                            </div>

                            <div class="col-xl-4 col-md-6">
                                <div class="price-box th-ani active">
                                    <div class="price-title-wrap">
                                        <h3 class="box-title">Standard Plan</h3>
                                        <p class="subtitle">ULTIMATE</p>
                                    </div>
                                    <p class="box-text">Best suits for great company!</p>
                                    <h4 class="box-price">$249.00<span class="duration">/month</span></h4>
                                    <p class="box-text2">If you a finance manager at big company, this plan is a perfect match.
                                    </p>
                                    <div class="box-content">
                                        <div class="available-list">
                                            <ul>
                                                <li>Limited Access Library</li>
                                                <li>Commercia License</li>
                                                <li>Hotline Support 24/7</li>
                                                <li class="unavailable">100+ HTML UI Elements</li>
                                                <li class="unavailable">WooCommerce Builder</li>
                                                <li class="unavailable">Updates for 1 Year</li>
                                            </ul>
                                        </div>
                                        <a href="contact.html" class="th-btn btn-fw style-radius">Get Your Standard plan</a>
                                    </div>
                                </div>
                            </div>

                            <div class="col-xl-4 col-md-6">
                                <div class="price-box th-ani ">
                                    <div class="price-title-wrap">
                                        <h3 class="box-title">Premium Plan</h3>
                                        <p class="subtitle">PRO</p>
                                    </div>
                                    <p class="box-text">Perfect plan for professionals!</p>
                                    <h4 class="box-price">$299.00<span class="duration">/month</span></h4>
                                    <p class="box-text2">For professional only! Start arranging your expenses with our best
                                        templates.</p>
                                    <div class="box-content">
                                        <div class="available-list">
                                            <ul>
                                                <li>Limited Access Library</li>
                                                <li>Commercia License</li>
                                                <li>Hotline Support 24/7</li>
                                                <li class="unavailable">100+ HTML UI Elements</li>
                                                <li class="unavailable">WooCommerce Builder</li>
                                                <li class="unavailable">Updates for 1 Year</li>
                                            </ul>
                                        </div>
                                        <a href="contact.html" class="th-btn btn-fw style-radius">Get Your Premium Plan</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}

            <div class="overflow-hidden space" id="faq-sec">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-lg-8">
                            <div class="title-area">
                                <span class="sub-title">FAQ'S</span>
                                <h2 class="sec-title">Frequently Ask Questions</h2>
                            </div>
                            <div class="accordion-area accordion" id="faqAccordion">


                                <div class="accordion-card style3 style1 ">
                                    <div class="accordion-header" id="collapse-item-1">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-1" aria-expanded="false" aria-controls="collapse-1">Will this app support any business model?</button>
                                    </div>
                                    <div id="collapse-1" class="accordion-collapse collapse " aria-labelledby="collapse-item-1" data-bs-parent="#faqAccordion">
                                        <div class="accordion-body">
                                            <p class="faq-text">Yes, if you're running a business that deals with physical goods, this app is an ideal choice to set up. However, it's important to ensure that your business complies with the necessary regulations, legal approvals, and registration documents required to operate.</p>
                                        </div>
                                    </div>
                                </div>


                                <div class="accordion-card style3 style1 active">
                                    <div class="accordion-header" id="collapse-item-2">
                                        <button class="accordion-button " type="button" data-bs-toggle="collapse" data-bs-target="#collapse-2" aria-expanded="true" aria-controls="collapse-2">Will there be any additional charges after purchasing the software subscription?</button>
                                    </div>
                                    <div id="collapse-2" class="accordion-collapse collapse show" aria-labelledby="collapse-item-2" data-bs-parent="#faqAccordion">
                                        <div class="accordion-body">
                                            <p class="faq-text">You will need to cover regular cloud hosting charges based on your required configuration, as well as SMS alerts and standard payment gateway charges, which may vary depending on your agreement with the payment gateway provider. Additionally, there will be charges for using the Google Maps API. All these costs depend on your configuration and usage volume.</p>
                                        </div>
                                    </div>
                                </div>


                                <div class="accordion-card style3 style1 ">
                                    <div class="accordion-header" id="collapse-item-3">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-3" aria-expanded="false" aria-controls="collapse-3">How will the shipment or delivery be handled?</button>
                                    </div>
                                    <div id="collapse-3" class="accordion-collapse collapse " aria-labelledby="collapse-item-3" data-bs-parent="#faqAccordion">
                                        <div class="accordion-body">
                                            <p class="faq-text">We are partnered with multiple shipment handlers, and you have the option to choose from them or use your own delivery process. You can track the entire delivery process and shipment details through our delivery partner app.</p>
                                        </div>
                                    </div>
                                </div>


                                <div class="accordion-card style3 style1 ">
                                    <div class="accordion-header" id="collapse-item-4">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-4" aria-expanded="false" aria-controls="collapse-4">What is a Quick Commerce ?</button>
                                    </div>
                                    <div id="collapse-4" class="accordion-collapse collapse " aria-labelledby="collapse-item-4" data-bs-parent="#faqAccordion">
                                        <div class="accordion-body">
                                            <p class="faq-text">Quick commerce (q-commerce) is a type of e-commerce that focuses on delivering products and services to customers rapidly, typically within hours or even minutes. It represents the next step in the evolution of e-commerce, characterized by speed and convenience. Examples include businesses like restaurants, grocery stores, milk delivery, and more.</p>
                                        </div>
                                    </div>
                                </div>


                                <div class="accordion-card style3 style1 ">
                                    <div class="accordion-header" id="collapse-item-5">
                                        <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapse-5" aria-expanded="false" aria-controls="collapse-5">Can I automate the settlement of vendor, Suppliers and delivery partner payments?</button>
                                    </div>
                                    <div id="collapse-5" class="accordion-collapse collapse " aria-labelledby="collapse-item-5" data-bs-parent="#faqAccordion">
                                        <div class="accordion-body">
                                            <p class="faq-text">Yes, you can automate this process. We have partnered with multiple payment gateways to handle payment settlements. Once an order is delivered, you can automatically split the payments between your company, suppliers, vendors, and delivery partners.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <aside class="sidebar-area style2">
                                <div class="widget widget_search   ">
                                    <form class="search-form">
                                        <input type="text" placeholder="Enter Keyword" />
                                        <button type="submit"><i class="far fa-search"></i></button>
                                    </form>
                                </div>
                                <div class="widget widget_offer  ">
                                    <div class="offer-banner">
                                        {/* <div class="banner-logo">
                                            <img src="../white.png" style={{ width: 150 }} alt="Sassa" />
                                        </div> */}
                                        <h5 class="banner-title">Need Help? We Are Here To Help You</h5>
                                        <div class="offer">
                                            <h6 class="offer-title">Glancein Special Discount</h6>
                                            <p class="offer-text">Save up to <span class="text-theme">60% off</span></p>
                                            <a href="contact.html" class="th-btn">Get A Quote</a>
                                        </div>
                                    </div>
                                </div>
                            </aside>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default Home;
