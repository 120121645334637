import React from 'react';

function Footer() {
    return (
        <div>
            <footer class="footer-wrapper footer-layout1">
                <div class="copyright-wrap text-center">
                    <div class="container">
                        <p class="copyright-text">Copyright <i class="fal fa-copyright"></i> 2024 <span>Glancein Enterprises Pvt Ltd</span>. All Rights Reserved.</p>
                    </div>
                </div>
            </footer>
        </div>
    );
};

export default Footer;
