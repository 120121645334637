import React, { useEffect } from 'react';

function About() {

    useEffect(() => {
        document.title = 'About Us - Glancein Enterprises Pvt Ltd';
    }, []);

    return (
        <div>
            <div class="breadcumb-wrapper">
                <div class="container">
                    <div class="breadcumb-content">
                        <h1 class="breadcumb-title">About Us</h1>
                        <ul class="breadcumb-menu">
                            <li><a href="/">Home</a></li>
                            <li>About Us</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="position-relative overflow-hidden overflow-hidden space">
                <div class="container">
                    <div class="col-xxl-12 col-lg-12">
                        <div class="page-single">
                            <div class="page-content">
                                <p class="">Glancein Enterprises was founded with the mission to provide cutting-edge solutions to businesses of all sizes, from small startups to large-scale industries. Led by experienced tech founders and teams who have explored diverse business models and domains, we aim to introduce AI and SaaS solutions to empower businesses. Our goal is to help startups and established companies alike run their operations smoothly, leveraging digital technologies to thrive in today’s fast-paced, ever-evolving marketplace.</p>
                                <p>
                                    Our SaaS eCommerce and IntelliERP solutions are powered by Artificial Intelligence and Microsoft Azure cloud technologies. They support various business domains with data-driven analytics. Our global cloud infrastructure ensures 99.99% uptime, with geo-redundant data centers to prevent downtime and provide seamless availability.</p>
                                <h4 class="mt-40 mb-20">Our Vision :</h4>
                                <p class="mb-40">Our vision is to make SaaS applications accessible to every business, enabling them to easily set up their marketplace or ERP system, track everything digitally, and automate workflows effortlessly. We strive to help businesses save time, reduce costs, and streamline operations, empowering them to focus on growth and innovation without the burden of complex setups.</p>
                                <div class="checklist project-checklist style2 mb-30">
                                    <ul>
                                        <li><span>Automate Workflow:</span>Automate workflows effortlessly, saving time and resources while boosting operational efficiency.</li>
                                        <li><span>Track Everything:</span>Gain complete visibility with real-time tracking of operations, from inventory to sales and beyond.</li>
                                        <li><span>Easy Access via Mobile and Web:</span>Access your business anytime, anywhere, with seamless mobile and web integration for full control.</li>
                                        <li><span>Data Analytics and Reports:</span>Unlock valuable insights with powerful data analytics and comprehensive, easy-to-understand reports.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-center" style={{ marginTop: 50 }}>
                        <div class="col-lg-7">
                            <div class="title-area text-center">
                                <h2 class="sec-title">Why Glancein ?</h2>
                                <p class="sec-text">Clearly communicate the unique value proposition of your SaaS product and what sets it apart from competitors.</p>
                            </div>
                        </div>
                    </div>
                    <div class="row gy-4 justify-content-center">
                        <div class="col-md-6 col-xl-3">
                            <div class="feature-card th-ani">
                                <div class="box-icon">
                                    <img src="../img/icon/feature_1_1.svg" alt="Icon" />
                                </div>
                                <h3 class="box-title"><a href="service-details.html">Software Features</a></h3>
                                <p class="box-text">Cloud-based and AI-powered platform, offering seamless automation, data analytics, and scalable performance for all businesses</p>
                            </div>
                        </div>

                        <div class="col-md-6 col-xl-3">
                            <div class="feature-card th-ani">
                                <div class="box-icon">
                                    <img src="../img/icon/feature_1_2.svg" alt="Icon" />
                                </div>
                                <h3 class="box-title"><a href="service-details.html">Customization Option</a></h3>
                                <p class="box-text">Easily customize workflows and features to fit your business needs, ensuring flexibility and scalability as your business grows.</p>
                            </div>
                        </div>

                        <div class="col-md-6 col-xl-3">
                            <div class="feature-card th-ani">
                                <div class="box-icon">
                                    <img src="../img/icon/feature_1_3.svg" alt="Icon" />
                                </div>
                                <h3 class="box-title"><a href="service-details.html">Customer Support</a></h3>
                                <p class="box-text">24/7 dedicated support with live chat, email, and multilingual assistance to help you every step of the way, from setup to optimization.</p>
                            </div>
                        </div>

                        <div class="col-md-6 col-xl-3">
                            <div class="feature-card th-ani">
                                <div class="box-icon">
                                    <img src="../img/icon/feature_1_4.svg" alt="Icon" />
                                </div>
                                <h3 class="box-title"><a href="service-details.html">Subscription Plans</a></h3>
                                <p class="box-text">Flexible SaaS plans designed for all business sizes, offering affordable pricing, automatic updates, and scalable tools for growth.</p>
                            </div>
                        </div>

                    </div>
                </div>
                <div class="shape-mockup z-index-3 movingX d-none d-xl-block" data-top="18%" data-left="18%"><span class="feature-shape style1"></span></div>
                <div class="shape-mockup movingX d-none d-xl-block" data-top="15%" data-right="15%"><span class="feature-shape style2"></span></div>
                <div class="shape-mockup movingX d-none d-xl-block" data-bottom="30%" data-left="5%"><span class="feature-shape style3"></span></div>
                <div class="shape-mockup movingX d-none d-xl-block" data-bottom="20%" data-right="10%"><span class="feature-shape style4"></span></div>
            </div>

            <div class=" overflow-hidden space-bottom">
                <div class="container">
                    <div class="row justify-content-center">
                        <div class="col-lg-7">
                            <div class="title-area text-center">
                                <h2 class="sec-title" style={{ marginTop: 10 }}>Powering Your Business with Seamless Solutions</h2>
                            </div>
                        </div>
                    </div>
                    <div class="process-wrap">
                        <div class="process-bg-line">
                            <img src="../img/bg/process_line.svg" alt="img" />
                        </div>
                        <div class="row gy-80 justify-content-center justify-content-lg-between align-items-center">
                            <div class="col-lg-6">
                                <div class="process-thumb-1">
                                    <img src="../img/normal/process-img-1-1.png" style={{ borderRadius: 30 }} alt="img" />
                                </div>
                            </div>
                            <div class="col-xl-5 col-lg-6">
                                <div class="process-content me-xl-5">
                                    <h4 class="process-content-title mb-25">E-Commerce and Quick Commerce!</h4>
                                    <p class="mb-25">Our eCommerce and quick commerce SaaS model offers a seamless digital marketplace platform where you can easily set up and manage your store or business model in just a few steps. Save time and effort by eliminating the need to build from scratch or hire a large IT team. We handle all updates, support, and enhancements, ensuring your platform runs smoothly while you focus on growing your business.</p>
                                    <div class="two-column mb-35">
                                        <div class="checklist">
                                            <ul>
                                                <li><i class="fas fa-check"></i>Cross-Platform Access (Web, Android & iOS)</li>
                                                <li><i class="fas fa-check"></i>Delivery Partner Solutions</li>
                                                <li><i class="fas fa-check"></i>Vendor & Supplier Management Apps</li>
                                                <li><i class="fas fa-check"></i>Advanced Accounting & Analytics Tools</li>
                                            </ul>
                                        </div>
                                        <div class="checklist">
                                            <ul>
                                                <li><i class="fas fa-check"></i>Comprehensive Shipping & Delivery Solutions</li>
                                                <li><i class="fas fa-check"></i>Scalable Global Cloud Infrastructure</li>
                                                <li><i class="fas fa-check"></i>AI-Powered Intelligence</li>
                                                <li><i class="fas fa-check"></i>Secure Payment Processing & Vendor Settlements</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="btn-wrap">
                                        <a href="about.html" class="th-btn style-radius">Discover More</a>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6 order-lg-4">
                                <div class="process-thumb-1">
                                    <img src="../img/normal/process-img-1-2.png" style={{borderRadius:30}} alt="img" />
                                </div>
                            </div>
                            <div class="col-xl-5 col-lg-6">
                                <div class="process-content left-content me-xl-5">
                                    <h4 class="process-content-title mb-25">IntelliERP – Tailored ERP Solution</h4>
                                    <p class="mb-25">IntelliERP is a customizable ERP solution designed to adapt to your specific business needs. With seamless integration, automation, and AI-powered insights, IntelliERP streamlines operations, enhances efficiency, and scales effortlessly. It provides full control over your business processes, from finance and supply chain management to HR, tracking, and much more, ensuring that every aspect of your business is optimized for growth and success.</p>
                                    <div class="two-column mb-35">
                                        <div class="checklist">
                                            <ul>
                                                <li><i class="fas fa-check"></i> Smart Accounting Hub</li>
                                                <li><i class="fas fa-check"></i> DocuFlow Manager</li>
                                                <li><i class="fas fa-check"></i> AI-Powered Doc Automation </li>
                                                <li><i class="fas fa-check"></i> HR & Attendance Tracker </li>
                                            </ul>
                                        </div>
                                        <div class="checklist">
                                            <ul>
                                                <li><i class="fas fa-check"></i> EasyPayroll</li>
                                                <li><i class="fas fa-check"></i> Inventory & Warehouse Control</li>
                                                <li><i class="fas fa-check"></i> askMaster & Reminders </li>
                                                <li><i class="fas fa-check"></i> Flexible Customization  </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="btn-wrap">
                                        <a href="about.html" class="th-btn style-radius">Discover More</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
};

export default About;
